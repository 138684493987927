<template>
  <div class="coupon-details-wrapper flex">
    <d-search class="d-search" :searchData="searchData" @searchEvent="search()" @resetEvent="resetSearch" />
    <div class="inline-flex contentBox">
      <el-table :data="tableData" class="contentTable" size="medium">
        <el-table-column prop="businessName" label="任务名称"></el-table-column>
        <el-table-column prop="createTime" label="日志时间"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 1 ? '成功' : '失败' }}
          </template>
        </el-table-column>
        <el-table-column prop="taskDuration" label="时长"></el-table-column>
        <el-table-column prop="remind" label="提示"></el-table-column>
        <el-table-column label="操作" :width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="handleClickDownload(scope.row.id)" v-if="scope.row.status !== 1">下载</el-button>
            <el-button style="color: #F56C6C" type="text" @click="handleClickDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <d-paging :pager="pager" style="marginTop:50px;" @change="getTableList" />
    </div>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DPaging from "@/components/d-paging";
import { onDownload } from "@/utils/utils";
import validateTime from "@/mixins/validateTime";

export default {
  name: "sysLog",
  components: {
    DPaging,
    DSearch
  },
  mixins: [validateTime],
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      tableData: [],
      searchData: {
        searchItem: [
          {
            label: '状态',
            value: 'status',
            type: 'commonSelect',
            placeholder: '',
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '成功',
                value: 1,
              },
              {
                label: '失败',
                value: 2
              }
            ],
          },
          {
            label: '日期',
            value: 'time',
            type: 'commonDatetime',
            clearable: false,
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期"
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          status: 2,
          time: ['', '']
        }
      },
    }
  },
  mounted () {
    this.searchData.params.time = this.getTimeRange('', 7, new Date());
    this.getTableList()
  },
  methods: {
    search () {
      this.pager.page = 1
      this.getTableList()
    },
    getTableList () {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
      }
      obj.beginCreateTime = (this.searchData.params.time && this.searchData.params.time[0]) || '';
      obj.endCreateTime = (this.searchData.params.time && this.searchData.params.time[1]) || ''
      obj.status = this.searchData.params.status || ''
      this.$api.sysapi.getSysLogList(obj).then(res => {
        if (res.code === 200) {
          this.pager.count = res.data.total
          this.tableData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleClickDownload (id) {
      this.$api.sysapi.exportSysLog({ id }).then((res) => {
        let { headers, data } = res;
        onDownload(headers, data);
      })
    },
    handleClickDelete (id) {
      this.$confirm('确定删除此条数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.sysapi.deleteSysLog({ id }).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.getTableList()
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => { })
    },
    resetSearch () {
      this.searchData.params.status = 2
      this.searchData.params.time = this.getTimeRange('', 7, new Date());
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped></style>