<template>
  <div class="userAuthority">
    <div class="header">
      <div class="headerTitle">
        <h4>用户信息</h4>
      </div>
    </div>
    <div class="userInfo">
      <el-row>
        <el-col :span="6">
            姓名：{{userInfo.name}}
        </el-col>
        <el-col :span="6" :offset="2">
            性别：{{userInfo.sex}}
        </el-col>
        <el-col :span="6" :offset="2">
            手机号：{{userInfo.mobile}}
        </el-col>
      </el-row>
       <el-row>
        <el-col :span="6">
            身份证号：{{userInfo.idCard}}
        </el-col>
        <el-col :span="6" :offset="2">
            部门：{{userInfo.deptName}}
        </el-col>
        <el-col :span="6" :offset="2">
            职位：{{userInfo.positionName}}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
            角色：{{userInfo.userRoleList}}
        </el-col>
        <el-col :span="6" :offset="2">
            工号：{{userInfo.jobNumber}}
        </el-col>
      </el-row>
       <el-row>
        <el-col :span="14">
            备注：  
        </el-col>
      </el-row>
    </div>

    <div class="globeSegment"></div>

    <div class="header">
      <div class="headerTitle">
        <h4>数据权限</h4>
      </div>
    </div>

    <div class="authority">
      <span class="hint">设置角色对应查看的数据范围</span>
        <el-row class="commercial">
          <el-col :span="2" class="title"> <span>选择业态：</span></el-col>
          <el-col :span="10">
             <el-select v-model="commercial" multiple placeholder="请选择" size="small">
              <el-checkbox class="commercialSelectAll" v-model="checked" @change='selectAll'>全选</el-checkbox>
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="commercial">
          <el-col :span="2" class="title"><span>选择楼层：</span></el-col>
          <el-col :span="16" class="checkbox">
            <div class="checkboxHeader">
              <el-checkbox :indeterminate="floorData.isIndeterminate" v-model="floorData.checkAll" @change="handleCheckAllChange">全部选择</el-checkbox>
            </div>
            <div class="checkboxContent">
              <el-checkbox-group v-model="floorData.checkedFloor" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="floor in floorData.floorOptions" :label="floor.id" :key="floor.id">{{floor.name}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
    </div>

    <div class="footer">
      <el-button size="medium" @click="$router.push({path:'/systemModule/auth/user'})">取消</el-button>
      <el-button size="medium" type="primary" @click="authorization">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      commercial:[],
      checked:false,
      floorData:{
        floorOptions:[],
        backups:[],
        checkAll:false,
        isIndeterminate:false,
        checkedFloor:[]
      },
      options: [],
      userInfo:{}
    }
  },
  computed: {
  },
  components: {

  },
  created () {
    this.getUserInfo()
    this.getBusinessList()
    this.getFloorList()
  },
  mounted () {
  },
  methods: {
    selectAll(){
      this.commercial = []
      if(this.checked){
        this.options.forEach(ele => {
          this.commercial.push(ele.id)
        });
      }else{
          this.commercial = []
      }
    },
    handleCheckAllChange(val) {
        this.floorData.checkedFloor = val ? this.floorData.backups : [];
        this.floorData.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.floorData.checkAll = checkedCount === this.floorData.floorOptions.length;
      this.floorData.isIndeterminate = checkedCount > 0 && checkedCount < this.floorData.floorOptions.length;
    },
    /**
     * 用户授权
     */
    authorization(){
      const obj = {
        id:this.$route.query.id,
        businessIds:this.commercial,
        floorIds:this.floorData.checkedFloor
      }
      this.$api.sysapi.sysUserAuthorization(obj)
        .then(({code, data, msg})=>{
          if(code === 200){
             this.$message({
                message: msg,
                type: 'success'
              });
              this.$router.push({path:'/systemModule/auth/user'})
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 获取用户详情
     */
    getUserInfo(){
      this.$api.sysapi.getUserInfo(this.$route.query.id)
        .then(({code, msg, data})=>{
          if(code === 200){
            this.userInfo = {
              ...data,
              status:data.status === 0 ? '离职' : data.status === 1 ? '在职' : data.status === 2 ? '未激活' : data.status === 3 ? '离职（未退押金）' : '',
              sex:data.sex === 'M' ? '男' : '女',
              userBusinessList: data.userBusinessList ?  data.userBusinessList.join('、') : '',
              userFloorList:data.userFloorList ? data.userFloorList.join('、') : '',
              userRoleList:data.userRoleList.map((item)=>item.roleName).join('、')
            }
            this.commercial = data.userBusinessList ? data.userBusinessList.map((item)=>item.id) : []
            this.floorData.checkedFloor = data.userBusinessList ? data.userFloorList.map((item)=>item.id) : []
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 获取业态列表
     */
    getBusinessList(){
      this.$api.sysapi.getBusinessList()
        .then(({code, data, msg})=>{
          if(code === 200){
            this.options = data
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 获取楼层列表
     */
    getFloorList(){
      this.$api.sysapi.getFloorList()
        .then(({code, data, msg})=>{
          if(code === 200){
            this.floorData.floorOptions = data
            this.floorData.backups = data.map((item)=>item.id)
          }else{
            this.$message.error(msg);
          }
          
        })
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .commercialSelectAll{
    margin-left: 80%;
  }
  .userAuthority{
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    overflow-y: auto;
    .header{
      padding: 16px;
      height: 55px; 
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
    }
    .userInfo{
      padding: 10px 60px;
      .el-row{
        margin: 30px 0;
      }
    }
    .authority{
      padding: 20px 60px 65px;
      .hint{
        color: #ccc;
      }
      .commercial{
        padding-left: 10px;
        margin-top: 30px;
        line-height: 32px;
        .title{
          min-width: 70px;
        }
        .el-select{
          width: 100%;
        }
      }
      .checkbox{
        border: 1px solid #ccc;
        .checkboxHeader{
          width: 100%;
          height: 35px;
          color: #949494;
          background-color: rgba(242, 242, 242, 1);
          padding-left: 20px;
          box-sizing: border-box;
          .el-checkbox{
             color: #949494;
          }
        }
        .checkboxContent{
          width: 100%;
          height: 202px;
          padding: 0 20px;
          box-sizing: border-box;
          .el-checkbox{
            width: 100px;
          }
        }
      }
    }

    .footer{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 56px;
      box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
      text-align: right;
      padding-right: 40px;
      box-sizing: border-box;
      background: #fff;
      z-index: 1;
      .el-button{
        margin-top: 8px;
      }
    }
  }

</style>
