<template>
  <div class="coupon-details-wrapper flex">
    <d-search class="d-search"
              :searchData="searchData"
              @searchEvent="search(1)" @resetEvent="resetSearch"/>
    <div class="inline-flex contentBox">
      <d-table :columnData="columnData"
               class="contentTable"
               :tableData="tableData"></d-table>
      <d-paging :pager="pager"
                style="marginTop:50px;"
                @change="search" />
    </div>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {deepClone} from "@/components/dynamicForm/utils/utils";
import validateTime from "@/mixins/validateTime";

export default {
  name: "biLog",
  components: {
    DPaging,
    DTable,
    DSearch
  },
  mixins:[validateTime],
  data(){
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      searchData: {
        searchItem: [
          {
            label: '用户名',
            value: 'userName',
            type: 'commonInput',
            placeholder: '请输入用户名'
          },
          {
            label: '日期',
            value: 'time',
            type: 'commonDatetime',
            clearable:false,
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期"
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          userName: '',
          time:['','']
        }
      },
      columnData: [
        { prop: "userName", label: "用户名" },
        { prop: "deptName", label: "部门" },
        { prop: "content", label: "日志" },
        { prop: "updateTime", label: "更新日期" },
      ],
      tableData: []
    }
  },
  mounted(){
    this.searchData.params.time = this.getTimeRange('',7,new Date());
    this.search()
  },
  methods:{
    // 查询
    search(page){
      page && (this.pager.page = 1,this.pager.rows = 10)
      let requestParam = this.getParams();
      if(!this.validateOneYear(requestParam.beginCreateTime,requestParam.endCreateTime)) return;
      this.$api.sysapi.getBiLogList(requestParam).then(res=>{
        this.pager.count = res.data.total
        this.tableData = res.data.list
      })
    },

    getParams(){
      const params = deepClone(this.searchData.params)
      let beginCreateTime = (params.time&&params.time[0])||'';
      let endCreateTime = (params.time&&params.time[1])||''
      delete params.time;
      return {
        ...params,
        ...{
          pageNum: this.pager.page,
          pageSize: this.pager.rows,
          beginCreateTime,
          endCreateTime
        }
      }
    },

    resetSearch(){
      this.searchData.params.time = this.getTimeRange('',7,new Date());
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>