<template>
  <div class="department">
    <el-row class="content">
      <el-col :span="6" class="contentLeft">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" highlight-current></el-tree>
      </el-col>
      <el-col :span="14" :offset="2" class="contentFight" v-if="Object.keys(deptInfo).length">
        <div v-if="deptInfo.pdeptName">上级部门：{{deptInfo.pdeptName}}</div>
        <div>部门名称：{{deptInfo.deptName}}</div>
        <div>部门描述：{{deptInfo.comment || '无描述信息'}}</div>
      </el-col>
    </el-row>


  </div>
</template>

<script>

export default {
  data(){
    return {
      data: [],
      deptInfo:{},
      defaultProps: {
        children: 'child',
        label: 'deptName'
      }
    }
  },
  created () {
    this.getSysDeptTree()
  },
  methods: {
    handleNodeClick(data) {
      this.$api.sysapi.getSysDeptInfo(data.id)
        .then(({code , data , msg})=>{
          this.deptInfo = data
        })
    },

    getSysDeptTree(){
      this.$api.sysapi.getSysDeptTree()
        .then(({code , data , msg})=>{
          if(code === 200){
            if(data !== null){
              this.data = [data]
            }
          }else{
            this.$message.error(msg);
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .department{
    background-color: #fff;
    flex: 1;
    overflow-y: auto;
  }
  .content{
      flex: 1;
      padding: 20px 30px;
      font-size: 14px;
      color: rgba(0, 0, 0, .85);
    .contentLeft{
      border-right: 1px solid #ccc;
      height: 100%;
    }
    .contentFight{
      div{
        margin: 30px 0 0 30px;
      }
    }
  }
</style>
