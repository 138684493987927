<template>
  <div class="mainShop" v-loading="loading">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
    <div class="content">
      <div class="header">
        <div class="operation">
          <el-button size="medium" type="primary" @click="syncShop">同步店铺</el-button>
        </div>
      </div>
      <d-lable :columnData="columnData" :tableData="tableData" row-key="id"></d-lable>
    </div>
    <d-paging :pager="pager" @change="getTableList" />
  </div>
</template>

<script>
import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'

export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      loading: false,
      childList: [],
      allShopList: [],
      searchData: {
        searchItem: [
          {
            label: '店铺号',
            value: 'shopNo',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: '店铺名称',
            value: 'name',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: '业态',
            value: 'businessIds',
            type: 'commonSelect',
            placeholder: '',
            filterable: true,
            options: []
          }
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          name: '',
          shopNo: '',
          businessIds: ''
        }
      },
      columnData: [
        { prop: 'name', label: '店铺名称' },
        { prop: 'shopNo', label: '店铺号' },
        { prop: 'floorName', label: '楼层' },
        { prop: 'floor', label: '楼层编码' },
        { prop: 'codeName', label: '业态' },
        { prop: 'code', label: '业态编码' },
      ],
      tableData: []
    }
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.init()
  },
  mounted () { },
  methods: {
    /**
     * 搜索按钮点击
     **/
    searchEvent () {
      this.getTableList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.pager.count = 0;
      this.getTableList()
    },
    getBusinessList () {
      this.$api.sysapi.getBusinessList().then(res => {
        if (res.code === 200) {
          this.searchData.searchItem[2].options = res.data.map(item => {
            return {
              value: item.id,
              label: item.name,
            }
          })
        }
      })
    },
    getTableList () {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        name: this.searchData.params.name,
        shopNo: this.searchData.params.shopNo,
        businessIds: this.searchData.params.businessIds,
      }
      this.$api.shopapi.getShopListV2(obj).then(res => {
        if (res.code === 200) {
          this.pager.count = res.data.total
          this.tableData = res.data.list
        }
      })
    },

    //  同步店铺
    syncShop () {
      this.$api.shopapi.shopSynchronize().then(({ code, data, msg }) => {
        if (code === 200) {
          this.$message.success(msg)
          this.getTableList()
        } else {
          this.$message.error(msg)
        }
      })
    },

    /**
     * 初始化
     **/
    init () {
      this.getBusinessList()
      this.getTableList()
    }
  },

}
</script>
<style lang="scss" scoped>
.mainShop {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #fff;

    .el-table {
      flex: 1;
      overflow-y: auto;
    }
  }
}


.content {
  margin-top: 10px;
  padding: 30px;
  box-sizing: border-box;
  width: 100%;

  .el-table {
    margin-top: 10px;
  }
}

.header {
  position: relative;
  line-height: 28px;
  display: flex;
  height: 36px;

  .operation {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;

    div {
      line-height: 28px;
    }

    .el-button {
      margin-left: 20px;
    }

    .el-checkbox {
      height: 32px;
    }
  }
}


.el-select {
  width: 100%;
}

.d-paging {
  // margin-top: 50px;
  text-align: center;
  background: #fff;
}
</style>
