<template>
  <div class="user" v-if="$route.path==='/systemModule/auth/user'">
    <div class="header">
      <d-search class="d-search" :searchData="searchData"  @resetEvent="resetEvent" @searchEvent="searchEvent"></d-search>
    </div>

    <div class="content">
      <div class="title">
        <div class="operation">
          <el-checkbox-group v-model="isCheckedStaus" @change="getSysUserTree" >
            <el-checkbox class="userCheckbox" style="color:#5c6be8"  label="1">在职</el-checkbox>
            <el-checkbox class="userCheckbox" style="color:#5c6be8"  label="0">离职</el-checkbox>
          </el-checkbox-group>
<!--          <el-button size="small" type="primary" @click="createUser">新建</el-button>-->
          <el-button size="small" type="primary"  @click="syncUser">同步</el-button>
        </div>
      </div>
      <el-row class="userList">
        <el-col :span="5" class="division">
          <el-tree ref="deptTree" :data="data" :props="defaultProps" @node-click="handleDepartmentClick" highlight-current node-key="id" :default-expanded-keys="[1]"></el-tree>
        </el-col>
        <el-col :span="19" class="divisionTable">
          <d-lable class="table-content" :tableData='tableData' :columnData='columnData' @compile="compile" @rowClick="userRowClick" @accredit="userAuthority"></d-lable>
          <d-paging class="d-paging" :pager="pager" @change="getItemList"/>
        </el-col>
      </el-row>
    </div>

    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      size="40%"
      class="privilegeUserDrawer">
      <div class="drawerTitle">
        <div class="headerTitle">
          <h4>用户信息</h4>
          <div class="operator">
            <div class="operatorItem" @click="$router.push({path:'/systemModule/auth/user/create',query: {id:drawerData.id,type:'compile'}})">
              <i class="el-icon-edit"></i>
              <span>修改</span>
            </div>
            <!-- <div class="operatorItem">
              <i class="el-icon-s-tools"></i>
              
              <el-dropdown trigger="click" @command="handleCommand">
                <span>更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">离职</el-dropdown-item>
                  <el-dropdown-item command="2">重置密码</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div> -->
          </div>
          <div class="close" @click="drawer = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div class="cardInfo">
        <div class="userCard">
          <div class="avatar">
            <img :src="drawerData.icon" alt="">
          </div>
          <div class="info">
            <div class="name">
              {{drawerData.name}} <span>（{{drawerData.status}}）</span>
            </div>
            <div class="gender">
              性别：{{drawerData.sex}}  ｜  手机：{{drawerData.mobile}}
            </div>
          </div>
        </div>
        <div class="basicInfo">
          <h4>基本信息</h4>
          <el-row>
            <el-col :span="8" :offset="2">姓名：{{drawerData.name}}</el-col>
            <el-col :span="7">性别：{{drawerData.sex}}</el-col>
            <el-col :span="7">手机号：{{drawerData.mobile}}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8" :offset="2">部门：{{drawerData.deptName}}</el-col>
            <el-col :span="7">角色：{{drawerData.userRoleList}}</el-col>
            <el-col :span="7">职位：{{drawerData.positionName}}</el-col>
          </el-row>
          <el-row>
            <el-col :span="8" :offset="2">邮箱：{{drawerData.email}}</el-col>
            <el-col :span="7">工号：{{drawerData.jobNumber}}</el-col>
            <el-col :span="7" v-if="drawerData.landlineNumber">座机/分机：{{drawerData.landlineNumber}}-{{drawerData.extensionNumber}}</el-col>
          </el-row>
        </div>
        <div class="segment"></div>
        <div class="authorityInfo">
          <h4>权限信息</h4>
          <el-row>
            <el-col :span="12" :offset="2">
              业态：{{drawerData.userBusinessList}}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" :offset="2">
              楼层：{{drawerData.userFloorList}}
            </el-col>
          </el-row>
        </div>
      </div>
    </el-drawer>
  </div>
  <router-view v-else/>
</template>

<script>

import DSearch from '../../../components/d-search'
import DLable from '../../../components/d-table'
import DPaging from '../../../components/d-paging'
export default {
  data(){
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      isCheckedStaus:['1'],
      drawer: false,
      deparData:null,
      direction: 'rtl',
      storageParams:undefined,
      searchData:{
        searchItem:[{
          label:'部门',
          value:'departName',
          type:'commonInput',
          dateType:'month',
          placeholder:'请输入部门',
        },{
            label:'姓名',
            value:'nameId',
            type:'commonInput',
            dateType:'month',
            placeholder:'请输入名称',
        },{
          label: '手机号',
          value: 'mobile',
          type:  'commonInput',
          placeholder:'请输入手机号'
        }],
        searchBtn:{
          queryBtn:true,
          resetBtn:true
        },
        params:{
          departName:'',
          nameId:'',
          mobile:''
        }
      },
      defaultProps: {
        children: 'child',
        label: 'deptName'
      },
      data: [
        {
          id: 1,
          label: '暂无',
          children: []
        }, 
      ],

      columnData:[
        {prop:'jobNumber',label:'员工编号'},
        {prop:'name',label:'姓名',columnType:'click'},
        {prop:'mobile',label:'手机号'},
        {prop:'marketName',label:'场馆'},
        {prop:'deptName',label:'部门'},
        {prop:'createTime',label:'创建日期'},
        {prop:'status',label:'帐号状态'},
        {prop:'operate',label:'操作', width:150, type:{
          compile:true,
          accredit:true,
        }}
      ],
      tableData: [],
      drawerData:{}
    }
  },
  computed: {
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.getSysDeptTree()
    this.getSysUserTree()
  },
  beforeRouteLeave(to,from,next){
    if(to.path=='/systemModule/auth/user/authority'||to.path=='/systemModule/auth/user/create'){
      localStorage.setItem('userParam',JSON.stringify({params:this.searchData.params,deparData:this.deparData}))
    }
    next()
  },
  methods: {
    /**
     * click 编辑
     */
    compile(column){
      this.$router.push({path:'/systemModule/auth/user/create',query: {id:column.id,type:'compile'}})
    },
    /**
     * click 授权
     */
    userAuthority(column){
      this.$router.push({path:'/systemModule/auth/user/authority',query: {id:column.id}})
    },
    /**
     * 分页列表
     */
    getItemList(){
      this.getSysUserTree()
    },
    /**
     * 姓名click
     */
    userRowClick(row){
      this.getUserInfo(row.id)

    },
    /**
     * 搜索按钮点击
     */
    searchEvent(){
      this.pager.page = 1;
      this.getSysUserTree('search')
    },

    //  同步
    syncUser(){
      this.$api.sysapi.sychronizeUser().then(({code,data,msg})=>{
        if (code === 200) {
          this.deparData = null
         this.getSysDeptTree()
         this.getSysUserTree()
        } else {
          this.$message.error(msg)
        }
      })
    },
    /**
     * 用户详情
     */
    getUserInfo(id){
      this.$api.sysapi.getUserInfo(id)
        .then(({code, msg, data})=>{
          if(code === 200){
            this.drawer = true
            this.drawerData = {
              ...data,
              status:data.status === 0 ? '离职' : data.status === 1 ? '在职' : data.status === 2 ? '未激活' : data.status === 3 ? '离职（未退押金）' : '',
              sex:data.sex === 'M' ? '男' : '女',
              userBusinessList: data.userBusinessList ?  data.userBusinessList.map((item)=>item.name).join('、') : '',
              userFloorList:data.userFloorList ? data.userFloorList.map((item)=>item.name).join('、') : '',
              userRoleList:data.userRoleList.map((item)=>item.roleName).join('、')
            }
          }else{
            this.$message.error(msg);
          }
        })
    },
    // createUser(){
    //   this.$router.push({path:'/systemModule/auth/user/create',query: {type:'add',departmentID:this.deparData?this.deparData.id:''}})
    // },
    /**
     * 选择部门
     */
    handleDepartmentClick(data){
      this.deparData = data
      this.getSysUserTree()
    },
    /**
     * 重置
     */
    resetEvent(){
      this.tableData = []
      this.pager = {
        ...this.pager,
        page: 1,
        rows: 10,
      }
      this.searchData.params.nameId = '';
      this.searchData.params.departName = '';
      this.searchData.params.mobile = '';
      this.getSysUserTree()
    },
    /**
     * 抽屉按钮选择
     */
    handleCommand(command) {
      this.$message('click on item ' + command);
    },
    /**
     * 左侧树
     */
    getSysDeptTree(){
      this.$api.sysapi.getSysDeptTree()
        .then(({code , data , msg})=>{
          if(code === 200){
            if(data !== null){
              this.data = [data]
              if(this.deparData){
                this.$nextTick(() => {
                  this.$refs.deptTree.setCurrentKey(this.deparData.id)
                })
              }
            }
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 根据左侧树获取列表
     */
    getSysUserTree(type){
      // if(this.deparData){
        this.storageParams = localStorage.getItem('userParam')
        if(this.storageParams&&!type){
          this.searchData.params = JSON.parse(this.storageParams).params;
          this.deparData = JSON.parse(this.storageParams).deparData;
        }
       localStorage.removeItem('userParam')
        const obj = {
          pageNum:this.pager.page,
          pageSize:this.pager.rows,
          departName:this.searchData.params.departName||'',
          deptId:this.deparData ? this.deparData.id : null,
          name:this.searchData.params.nameId||'',
          mobile:this.searchData.params.mobile||'',
          status:this.isCheckedStaus.length===1?this.isCheckedStaus[0]:''
        }
        this.$api.sysapi.getSysUserTree(obj)
          .then(({code, msg , data})=>{
            if(code === 200){
              this.tableData = data.list.map((item)=>({
                ...item,
                status:item.status === 0 ? '离职' : item.status === 1 ? '在职' : item.status === 2 ? '未激活' : item.status === 3 ? '离职（未退押金）' : '',
                isCompile:item.status === 0 ? false :undefined,
                isAccredit:item.status === 0 ? false :undefined,
              }))
              this.pager.count = data.total
            }
          })
      // }else{
      //   this.$message.error('请选择部门');
      // }
   
    }
  },
  watch: {
    '$route'(to,from){
      if(to.path==='/systemModule/auth/user'){
        this.getSysDeptTree()
        this.getSysUserTree()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user{
  width: 100%;
  overflow: hidden;
  .header{
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    .d-search{
      padding-bottom: 20px;
    }
  }
  .d-paging{
    text-align: center;
    margin-top: 20px;
  }
  .content{
    width: 100%;
    flex: 1;
    background-color: #fff;
    margin-top: 12px;
    padding: 10px 30px;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
    .title{
      position: relative;
      line-height: 40px;
      height: 40px;
      .operation{
        position: absolute;
        top:4px;
        right: 50px;
        display: flex;
        div{
          line-height:28px;
        }
        .el-button{
          margin-left: 20px;
        }
        .el-checkbox{
          height: 32px;
        }
      }
    }
    .userList{
      margin-top: 5px;
      height: 92%;
      .division{
        height: 100%;
        padding: 10px;
        border: 1px solid #eee;
        overflow: auto;
      }
      .divisionTable{
        height: 100%;
        display: flex;
        overflow: auto;
        flex-direction: column;
        padding: 0 10px;
        .table-content{
          flex: 1;
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  .el-drawer__wrapper{
    top: 5%;
    bottom: 20%;
    color: rgba(0,0,0,.85);
    .drawerTitle{
      height: 55px; 
      border-bottom: 1px solid rgba(233, 233, 233, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      box-sizing: border-box;
      .headerTitle{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        .operator{
          position: absolute;
          right: 20px;
          display: flex;
          .operatorItem{
            margin: 0 10px;
            font-size: 14px;
            span{
              margin-left: 5px;
              color: #1890FF;
            }
          }
          .operatorItem:last-child{
            padding-right: 10px;
            border-right: 1px solid #ccc;
          }
        }
        .close{
          position: absolute;
          right: 0px;
          font-size: 22px;
        }
      } 
    }
    .cardInfo{
      width: 100%;  
      .userCard{
        border-bottom: 1px solid #ccc;
        display: flex;
        padding:  20px;
        .avatar{
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #1890FF;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .info{
          margin: 5px 0 0 20px;
          color: rgba(0, 0, 0, 0.65);
          .name{
            font-size: 16px;
            font-weight: 650;
            span{
              font-weight: 0;
              font-size: 14px;
              color: #1890FF;
            }
          }
          .gender{
            font-size: 13px;
            margin-top: 15px;
          }
        }
      }
      .basicInfo{
        padding: 20px;
        font-size: 14px;
        .el-row{
          margin-top: 30px;
          .el-col{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              height: 18px;
          }
        }
      }
      .segment{
        width: 100%;
        height: 10px;
        background-color: rgba(242, 242, 242, 1);
        margin-top: 10px;
      }
      .authorityInfo{
        padding: 20px;
        font-size: 14px;
        .el-row{
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
