<template>
  <div class="position">
    <d-search class="d-search" :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent"/>
    <div class="content">
      <div class="header">
        <h4>列表</h4>
        <el-button type="primary" size="small" icon="el-icon-plus" @click="addPosition('add')">新建</el-button>
      </div>

      <d-lable :tableData='tableData' :columnData='columnData' @compile="compile" @removeEvent="removeEvent"></d-lable>
      <d-paging class="d-paging" :pager="pager" @change="getItemList"/>
    </div>


    <el-dialog
      
      custom-class="positionDialog"
      :title="dialog.title"
      min-width = "500"
      :visible.sync="dialog.dialogVisible"
      :destroy-on-close='true'>
      <div class="dialogContent">
        <el-row class="dialogRow">
          <el-col :span="4"  :offset="4" class="title"><span>*</span>部门名称:</el-col>
          <el-col :span="12" class="division">
            <el-input v-model="dialog.postName" placeholder="请输入部门名称"></el-input>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="positionUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DSearch from '../../../components/d-search' 
import DLable from '../../../components/d-table'
import DPaging from '../../../components/d-paging'
export default {
  data(){
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      dialog:{
        dialogVisible:false,
        title:null,
        postName:null
      },
      searchData:{
        searchItem:[{
            label:'职位名称',
            value:'positionId',
            type:'commonInput',
            dateType:'month',
            placeholder:'请输入职位名称',
        }],
        searchBtn:{
          queryBtn:true,
          resetBtn:true
        },
        params:{}
      },
      columnData:[
        {prop:'code',label:'编号'},
        {prop:'positionName',label:'职位名称'},
        {prop:'quantity',label:'人数'},
        {prop:'createTime',label:'创建日期'},
        {prop:'operate',label:'操作',type:{
          compile:true,
          remove:true,
        }}
      ],
      tableData: []
    }
  },
  computed: {
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.getSysPositionList()
  },
  mounted () {
  },
  methods: {
    searchEvent() {
      this.getSysPositionList()
    },
    resetEvent(){
      this.pager = {
        ...this.pager,
        page:1,
        rows:10
      }
      this.tableData = []
      this.searchData.params.positionId = null

    },
    /**
     * 列表删除按钮点击
     */
    removeEvent(column){
      this.$api.sysapi.deletePosition(column.id)
        .then(({code, data, msg})=>{
          if(code === 200){
            this.$message({
              type: 'success',
              message: msg
            });
            this.getSysPositionList()
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * click 编辑
     */
    compile(column){
      this.dialog = {
        ...this.dialog,
        id:column.id,
        postName:column.positionName,
        title:'修改部门',
        dialogVisible:true
      }
    },
    /**
     * 添加按钮点击
     */
    addPosition(){
      this.dialog.postName = null
      this.dialog.dialogVisible = true
      this.dialog.title = '新建部门'
    },
    /**
     * 职位添加或者更新接口函数
     */
    positionUpdate(param){
      let obj = new Object()
      if(this.dialog.title = '新建部门'){
        obj = {
          positionName:this.dialog.postName
        }
      }else{
        obj = {
          id:this.dialog.id,
          positionName:this.dialog.postName
        }
      }
      this.$api.sysapi.positionUpdate(obj)
        .then(({code, data, msg})=>{
          if(code === 200){
            this.getSysPositionList()
            this.$message({
              type: 'success',
              message: msg
            });
            this.dialog.dialogVisible = false
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 查询职位列表
     */
    getSysPositionList(){
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        positionName:this.searchData.params.positionId,
        status:1,
      }
      this.$api.sysapi.getSysPositionList(obj)
        .then(({code, data, msg})=>{
          if(code === 200){
            this.pager.count = data.total
            this.tableData = data.list.map((item,index)=>({
              ...item,
              code:index+1
            }))
          }
        })
    },
    /**
     * 查询请求
     */
	  getItemList() {
      this.getSysPositionList()
    },
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .position{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .d-search{
      background-color: #fff;
      padding-bottom: 20px;
      border-radius: 5px;
    }
    .content{
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      margin-top: 15px;
      text-align: center;
      overflow: hidden;
      .header{
        height: 50px;
        padding: 0 20px;
        box-sizing: border-box;
        h4{
          float: left;
          line-height: 50px;
        }
        .el-button{
          float: right;
          margin-top: 9px;
        }
      }
      .el-table {
        flex: 1;
        overflow-y: auto;
      }
      .d-paging{
        margin-top: 20px;
      }
    }

    .dialogRow{
      display: flex;
      margin-top: 20px;
      .title{
        min-width: 80px;
        line-height: 40px;
        position: relative;
        span{
          position: absolute;
          left: -10px;
          top: 2px;
          color: red;
          margin-right: 4px;
        }
      }
    }
  }
</style>
