<template>
  <div class="createRole">
    <div class="header">
      <div class="headerTitle">
        <h4>角色信息</h4>
        <span>（设置角色对应的功能操作、应用管理、后台管理权限）</span>
      </div>
    </div>
    <div class="roleContent">
      <div class="roleName">
        <div class="title"><span>*</span>角色名称</div>
        <el-row>
          <el-col :span="6">
            <el-input size="medium" class="width400" type="text" placeholder="请输入内容" v-model="roleInfo.roleName" maxlength="10" />
          </el-col>
          <el-col :span="6" class="hint">
            {{roleInfo.roleName.length}}/10
          </el-col>
        </el-row>
      </div>
      <div class="roleDescription">
        <div class="title">角色描述</div>
        <el-row>
          <el-col :span="6">
            <el-input size="medium" class="width400" type="textarea" placeholder="请输入内容" v-model="roleInfo.roleDescription" maxlength="300" />
          </el-col>
          <el-col :span="6" class="hint">
            {{roleInfo.roleDescription.length}}/300
          </el-col>
        </el-row>
      </div>

    </div>
    <system-background v-if="compileFlag" class="background"  ref="refChild" :sysArr="compileArr"/>

    <div class="roleBtn">
      <el-button size="small" @click="$router.push({path:'/systemModule/auth/role'})">取消</el-button>
      <el-button size="small" type="primary" @click="sysRoleUpdate" :disabled="roleInfo.autoType==1">确定</el-button>
    </div>
  </div>
</template>

<script>
import SystemBackground from '../component/background'
export default {
  data(){
    return {
      roleInfo:{  // 角色信息对象
        roleName:'',
        roleDescription:'',
        autoType:''
      },
      compileArr:[],
      compileFlag:false
    }
  },
  computed: {
  },
  components: {
    SystemBackground
  },
  created () {
    this.id = this.$route.query.id
    this.getRoleInfo(this.id)
    this.getSysModuleTree()
  },
  mounted () {
  },
  methods: {
    getRoleInfo(obj){
      this.$api.sysapi.getRoleInfo(obj)
        .then(({code, msg ,data})=>{
          if(code === 200){
            this.roleInfo.roleName = data.name;
            this.roleInfo.roleDescription = data.comment;
            this.roleInfo.autoType = data.autoType;
          }else{
            this.$message.error(msg);
          }
        })
    },
    sysRoleUpdate(){
      this.$refs.refChild.handleCheckChange_Front()
      this.$refs.refChild.handleCheckChange_Backstage()
      const obj = {
        id:this.id,
        name:this.roleInfo.roleName,
        comment:this.roleInfo.roleDescription,
        moduleIds:[...this.$refs.refChild.frontArr,...this.$refs.refChild.backstageArr]
      }
      this.$api.sysapi.sysRoleUpdate(obj)
        .then(({code, msg, data})=>{
          if(code === 200){
            this.$router.push({path:'/systemModule/auth/role'})
          }else{
            this.$message.error(msg);
          }
        })
    },

    getSysModuleTree(){
      const obj = {
        roleId:this.id
      }
      this.$api.sysapi.getSysModuleTree(obj)
        .then(({code, msg, data})=>{
           if(code === 200){
            this.getSys(data.child) 
            this.compileFlag = true
          }else{
            this.$message.error(msg);
          }
        })
    },
    getSys(sys){
      if(sys.length > 0){
        sys.forEach(ele => {
         
          if(ele.child.length > 0){
            this.getSys(ele.child)
          }else{
            // canHandle--能不能操作  haveModule--有没有权限
            if(ele.haveModule && ele.id !== 2 && ele.id !== 3){
              this.compileArr.push(ele.id)
            }
          }
        });
      }
    }
  },
  watch: {
  }
} 
</script>
<style lang="scss" scoped>
.createRole{
  width: 100%;
  flex: 1;
  overflow-y: auto;
  background-color: #fff;
  .header{
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:15px;
    box-sizing: border-box;
    .headerTitle{
      display: flex;
      span{
        color: #999999;
        font-size: 14px;
      }
    }
  }
  .roleContent{
    padding: 20px 30px 0 30px;
    span{
      position: relative;
      top: 3px;
      left: -3px;
      color: red;
    }
    .title{
      font-size: 14px;
      color: #333333;
    }
    .el-row{
      margin-top: 15px;
      line-height: 32px;
    }
    .hint{
      margin-left: 10px;
      color: #999999;
    }
    .roleDescription{
      margin-top: 30px;
      .el-row{
        position: relative;
      }
      .hint{
        margin-top: 10px;
      }
    }
  }
  .roleBtn{
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 56px;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
    text-align: right;
    line-height: 56px;
    padding-right: 50px;
    box-sizing: border-box;
    background-color: #fff;
    z-index:99
  }
  .background{
    padding-bottom: 70px;
  }
}
</style>
