<template>
  <div class="createRole">
    <div class="header">
      <div class="headerTitle">
        <h4>角色信息</h4>
        <span>（设置角色对应的功能操作、应用管理、后台管理权限）</span>
      </div>
    </div>
    <div class="roleContent">
      <el-form class="roleName" :model="roleInfo" :rules="roleInfoRules" ref="roleInfoForm">
        <el-form-item prop="roleName" required>
          <div class="title"><span>*</span>角色名称</div>
          <el-row>
            <el-col :span="6">
              <el-input size="small"
                        type="text"
                        placeholder="请输入内容"
                        v-model="roleInfo.roleName"
                        maxlength="10" />
            </el-col>
            <el-col :span="6"
                    class="hint">
              {{roleInfo.roleName.length}}/10
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <div class="roleDescription">
            <div class="title">角色描述</div>
            <el-row>
              <el-col :span="6">
                <el-input size="small"
                          type="textarea"
                          placeholder="请输入内容"
                          v-model="roleInfo.roleDescription"
                          maxlength="300" />
              </el-col>
              <el-col :span="6"
                      class="hint">
                {{roleInfo.roleDescription.length}}/300
              </el-col>
            </el-row>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <system-background class="background"
                       ref="refChild" />

    <div class="roleBtn">
      <el-button size="medium"
                 @click="$router.push({path:'/systemModule/auth/role'})">取消</el-button>
      <el-button size="medium"
                 type="primary"
                 @click="sysRoleUpdate">确定</el-button>
    </div>
  </div>
</template>

<script>
import SystemBackground from '../component/background'
export default {
  data () {
    return {
      roleInfo: {  // 角色信息对象
        roleName: '',
        roleDescription: ''
      },
      roleInfoRules:{
        roleName:[{
          required:true,message:'请填写角色名称',trigger:'blur'
        }]
      }
    }
  },
  computed: {
  },
  components: {
    SystemBackground
  },
  created () {
  },
  mounted () {
  },
  methods: {
    sysRoleUpdate () {
      this.$refs.roleInfoForm.validate((validate)=>{
        if(validate){
          let model  = [...this.$refs.refChild.frontArr, ...this.$refs.refChild.backstageArr];
          if(!model.length){
            this.$message.error('请为角色分配权限');
            return ;
          }
          const obj = {
            name: this.roleInfo.roleName,
            comment: this.roleInfo.roleDescription,
            moduleIds: model
          }
          this.$api.sysapi.sysRoleUpdate(obj)
              .then(({ code, msg, data }) => {
                if (code === 200) {
                  this.$router.push({ path: '/systemModule/auth/role' })
                } else {
                  this.$message.error(msg);
                }
          })
        }
      })
    },
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.createRole {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  background-color: #fff;
  .header {
    flex-shrink: 0;
    height: 55px;
    border-bottom: 1px solid rgba(233, 233, 233, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    .headerTitle {
      display: flex;
      span {
        color: #999999;
        font-size: 14px;
      }
    }
  }
  .roleContent {
    padding: 20px 30px 0 30px;
    span {
      position: relative;
      top: 3px;
      left: -3px;
      color: red;
    }
    .title {
      font-size: 14px;
      color: #333333;
    }
    .el-row {
      margin-top: 15px;
      line-height: 32px;
    }
    .hint {
      margin-left: 10px;
      color: #999999;
    }
    .roleDescription {
      margin-top: 30px;
      .el-row {
        position: relative;
      }
      .hint {
        margin-top: 10px;
      }
    }
  }
  .roleBtn {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 56px;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2);
    text-align: right;
    line-height: 56px;
    padding-right: 50px;
    box-sizing: border-box;
    background-color: #fff;
    z-index: 99;
  }
  .background {
    padding-bottom: 70px;
  }
}
</style>
