<template>
  <div class="operateLog">
    <d-search class="d-search"
              :searchData="searchData"
              @searchEvent="searchEvent"
              @resetEvent="resetEvent" />
    <div class="table">
      <h4>列表</h4>
      <d-lable :tableData='tableData'
               :columnData='columnData'></d-lable>
      <d-paging class="d-paging"
                :pager="pager"
                @change="getItemList" />
    </div>
  </div>
</template>

<script>

import DSearch from '../../../components/d-search'
import DLable from '../../../components/d-table'
import DPaging from '../../../components/d-paging'
import { options } from '../../../components/options'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      // options,
      searchData: {
        searchItem: [{
          label: '状态',
          value: 'status',
          type: 'commonSelect',
          placeholder: '',
          options: [{
            value: '成功',
            key: '1'
          }, {
            value: '失败',
            key: '2'
          }],
        }, {
          label: '选择店铺',
          value: 'shopIdList',
          type: 'linkageSelect',
          dateType: 'month',
          placeholder: '',
          options: options,
          optionProps: {
            value: 'name',
            label: 'name',
            children: 'childList'
          }
        }, {
          label: '扫码日期',
          value: 'commitDate',
          type: 'commonDate',
          startPlaceholder: "开始日期",
          endPlaceholder: "结束日期"
        }, {
          label: '姓名',
          value: 'name',
          type: 'commonInput',
          placeholder: '请输入职位名称',
        },
        {
          label: '设备号',
          value: 'deviceNumber',
          type: 'commonInput',
          placeholder: '请输入职位名称',
        }],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {
          status: null
        }
      },
      columnData: [
        { prop: 'createTime', label: '扫码时间', width: 200 },
        { prop: 'operator', label: '操作人' },
        { prop: 'floor', label: '楼层' },
        { prop: 'shopNo', label: '店铺号', width: '120' },
        { prop: 'shopName', label: '店铺名称', width: '120' },
        { prop: 'status', label: '状态' },
        { prop: 'deviceNumber', label: '设备号', width: '200' }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState(['DSarch'])
  },
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.getShopList()
    this.getScanLogList()
  },
  mounted () {
  },
  methods: {
    //  查询按钮
    searchEvent () {
      this.getScanLogList()
    },

    // 重置按钮
    resetEvent () {

    },
    getScanLogList () {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        createTime: this.searchData.params.commitDate,
        operator: this.searchData.params.name,
        deviceNumber: this.searchData.params.deviceNumber,
        status: this.searchData.params.status === '成功' ? 1 : 0,
        shop: this.searchData.params.shopIdList ? this.searchData.params.shopIdList[this.searchData.params.shopIdList.length - 1] : null,
      }
      this.$api.sysapi.getScanLogList(obj)
        .then(({ code, data, msg }) => {
          if (code === 200) {
            this.tableData = data.list.map((item) => ({
              ...item,
              status: item.status === 1 ? '成功' : '失败'
            }))
            this.pager.count = data.total
          }
        })
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach((item) => {
              if (item.type === 'linkageSelect') {
                item.options = child
              }
            })
          }
        })
    },
    // 查询请求
    getItemList () {
      this.getScanLogList()
    },
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.operateLog {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .d-search {
    padding-bottom: 20px;
    border-radius: 5px;
    background-color: #fff;
  }
  .table {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #fff;
    text-align: center;
    h4 {
      margin-bottom: 20px;
      text-align: left;
    }
    .el-table {
      flex: 1;
      overflow-y: auto;
    }
    .d-paging {
      // position: absolute;
      // left: 0;
      // right: 0;
      // margin: 0 auto;
      // bottom: 50px;
    }
  }
}
</style>
